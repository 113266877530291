<div id='cliRow'>

  <div id="firstDiv" (click)="inputNotFocused()" style="height:100vh; overflow: hidden;width: 30%;display:none;">
    <div class="searchBox" style="visibility: hidden;" [class.searchBoxFocused]="focus=== true">
      <!-- [(ngModel)]="typed" -->
      <input data-toggle="tooltip" title="Enter CLI query" (keyup.arrowright)="getTopOfThePredictionList()"
        (keyup.arrowdown)="test()" (keyup.arrowup)="getLastQuery()" class="float-left" (focus)="focus =true"
        (blur)="focus =false" #query id='cli-input' [value]="getVal()" (ngModel)="typed"
        (keyup)="onChange($event,query.value)" (keyup.enter)="onEnter(query.value)" />
      <input class="float-right" type='file' id="selectFileInput" (change)="fileChanged($event)" style="display: none;">
      <input data-toggle="tooltip" title="load script file" class="float-right" style="width:2.5em;  z-index: 2;"
        type="image" src="https://img.icons8.com/cotton/64/000000/red-file--v2.png" alt="Submit"
        onclick="document.getElementById('selectFileInput').click();" />

      <!-- <input id='cli-placeholder' disabled value="" /> -->

      <ul *ngIf="show" id="predictions">
        <li (click)="selected(item)" *ngFor="let item of predictionList; let i = index">{{item}}</li>
      </ul>
    </div>

    <!-- show query entered also the initial list of expressions  #47bd8f-->
    <p style="z-index: -50;color:#fff;padding: .2em;overflow: auto; max-height:70vh;" [innerHTML]="value">{{value}}
    </p>
    <!--  -->


    <a id="a_png" download><img title="right click / long press to save" class="hide" id="png"></a>

    <div class="hide" id="details">
    </div>

    <!-- show table -->


    <!-- show result -->

    <div id="result" style="  overflow-y: scroll;   " #TABLE #table>

      <!-- show result found (count) -->
      <p style="z-index: -50; color: #ec933b;padding: .2em;">{{resultCount}}</p>
      <!--  -->
      <div id="jsonResult" style="max-height:60vh;padding: 1em;">
        <!-- color: #dcfca1 -->
        <div (click)="onRowClick($event,row['id'])" class="row jsonResultRow" *ngFor="let row of result; index as i"
          style=" color:darkslategray;  margin-top: 1em; ">
          <!-- 1. Image -->
          <div style="pointer-events:none;padding: 0;" class="col-sm-3">
            <img *ngIf="row['gender']=='female'" src="assets/f.jpg" alt="image">
            <img *ngIf="row['gender']=='male'?row['lvl']!='md&ceo':null" src="assets/m.jpg" alt="image">
            <img class="boss" *ngIf="row['lvl']=='md&ceo'" src="assets/boss.jpg" alt="image">
          </div>
          <div style="pointer-events:none;" clas="col-sm-9 ">
            <span [class.female]="row['gender']=='female'" [class.male]="row['gender']=='male'">{{row['first_name']|
              uppercase}}</span><br>
            <span style="opacity: 1;
            color: rgb(0, 0, 0);">{{row['designation']| uppercase}}</span><br>

            <span style="color:#1abc9c;" *ngFor="let val of selectorsInQuery; index as j">{{row[val]}}<br></span>
            <!-- <span>
              <ngb-rating readonly="true" [rate]="getTenur(row['tnur'])" data-max="10"></ngb-rating>
            </span> -->
          </div>
        </div>
      </div>

      <div class="hide" id="tableForResult">
        <button (click)="ExportTOExcel()" class="m-1 float-right btn btn-sm btn-info">ExportTOExcel</button>

        <table style="max-height:50vh;" class="tableFixHead table-hover
        table table-striped table-dark table-sm table-responsive">

          <span id='resultTableSpan'>
            <tr class="hide">
              <th scope="col">Network Property</th>
              <th scope="col">Score</th>
            </tr>
            <tr class="hide" *ngFor="let item of network_metric_data | keyvalue">
              <th scope="row">{{item.key | titlecase }}</th>
              <td>{{item.value}}</td>
            </tr>
            <tr></tr>
          </span>
          <caption>result of query</caption>
          <thead>
            <tr>
              <th *ngFor="let colName of columnsForTable; index as i" scope="col">{{(colName.replace('_', ' ')) |
                titlecase }}</th>
            </tr>
          </thead>
          <tbody>

            <tr (click)="onRowClick($event,row['id'])" *ngFor="let row of result; index as i">

              <ng-container *ngIf="row['grey'] != true">
                <td [class.female]="j=='1'?row['gender']=='female':null"
                  [class.male]="j=='1'?row['gender']=='male':null" *ngFor="let colName of columnsForTable; index as j">
                  {{row[colName]}}</td>
              </ng-container>
              <ng-container *ngIf="autoDownload && result?.length - 1 == i">
                <!-- {{i=0}} -->
                <!-- <ng-container *ngIf= "autoDownload_event()">
                 {{i+1}}
                </ng-container> -->
              </ng-container>
            </tr>


          </tbody>
        </table>
      </div>

    </div>
    <!--  -->

  </div>

  <div id="secondDiv"
    style="z-index:1;height:100%; width: 100%;padding: 0; background-color: #cfd8dc;position: relative;">
    <div id="cag_modal_container" class="modalscreen-container">
      <div class="modalscreen-background">
        <div class="modalscreen" style="width:100%;height:100%;background-color: white;">
          <span class="closemodalscreen_cag" (click)="close_cag_modal()" class="material-symbols-outlined"
            style="position: absolute;top:10px;right:10px;z-index: 2000;cursor: pointer;">close</span>
          <div id="configure_a_graph_container"
            style="display:flex;flex-direction: column;height:90%;justify-content: space-evenly;align-items: center;">
            <label for="cag_configarea">Add your configuration below</label>
            <textarea id="cag_configarea" style="width:90%;margin:auto;height:90%;" #config_cag>{{json_temp}}</textarea>
            <button class="button-18" (click)="submit_cag(config_cag.value)" style="margin: auto;">Submit</button>
          </div>
        </div>
      </div>
    </div>
    <div id="stats_modal_container" class="modalscreen-container">
      <div class="modalscreen-background">
        <div class="modalscreen" style="width:100%;height:100%;background-color: white;">
          <span class="closemodalscreen_stats" (click)="close_stats_modal()" class="material-symbols-outlined"
            style="position: absolute;top:10px;right:10px;z-index: 2000;cursor: pointer;">close</span>
          <div id="stats_modal_content" style="width:70%;display:grid;height:90%;margin:auto;grid-template-columns: repeat(23,fit-content);
          grid-template-rows:repeat(5,max-content);column-gap: 20px;row-gap: 10px;">
            <span style="grid-row:1;grid-column:1/4;font-size: 20px;  ">Network Statistics</span>

            <div style="grid-row:2;grid-column:1;">

              <div class="card card-stats mb-4 mb-xl-0">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h5 class="card-title text-uppercase text-muted mb-0">Nodes</h5>
                      <span class="h2 font-weight-bold mb-0" id="stats_no_of_nodes">350,897</span>
                    </div>
                    <div class="col-auto">
                      <!-- <div class="icon icon-shape bg-danger text-white rounded-circle shadow"> -->
                      <span class="material-symbols-outlined" style="font-size:40px;">
                        line_start_circle
                      </span> <!-- </div> -->
                    </div>
                  </div>
                  <!-- <p class="mt-3 mb-0 text-muted text-sm">
                  <span class="text-success mr-2"><i class="fa fa-stroopwafel"></i> 3.48%</span>
                  <span class="text-nowrap">Since last month</span>
                </p> -->
                </div>
              </div>
            </div>
            <div style="grid-row:2;grid-column:2;">

              <div class="card card-stats mb-4 mb-xl-0">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h5 class="card-title text-uppercase text-muted mb-0">Edges</h5>
                      <span class="h2 font-weight-bold mb-0" id="stats_no_of_edges">350,897</span>
                    </div>
                    <div class="col-auto">
                      <!-- <div class="icon icon-shape bg-danger text-white rounded-circle shadow"> -->
                      <span class="material-symbols-outlined" style="font-size:40px;">
                        line_start_arrow
                      </span> <!-- </div> -->
                    </div>
                  </div>
                  <!-- <p class="mt-3 mb-0 text-muted text-sm">
                    <span class="text-success mr-2"><i class="fa fa-stroopwafel"></i> 3.48%</span>
                    <span class="text-nowrap">Since last month</span>
                  </p> -->
                </div>
              </div>
            </div>
            <div style="grid-row:2;grid-column:3;">

              <div class="card card-stats mb-4 mb-xl-0">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h5 class="card-title text-uppercase text-muted mb-0">Density</h5>
                      <span class="h2 font-weight-bold mb-0" id="stats_density">350,897</span>
                    </div>
                    <div class="col-auto">
                      <!-- <div class="icon icon-shape bg-danger text-white rounded-circle shadow"> -->
                      <span class="material-symbols-outlined" style="font-size:40px;">
                        density_medium
                      </span>
                    </div>
                  </div>
                  <!-- <p class="mt-3 mb-0 text-muted text-sm">
                    <span class="text-success mr-2"><i class="fa fa-stroopwafel"></i> 3.48%</span>
                    <span class="text-nowrap">Since last month</span>
                  </p> -->
                </div>
              </div>
            </div>
            <div style="grid-row:3;grid-column:1;">

              <div class="card card-stats mb-4 mb-xl-0">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h5 class="card-title text-uppercase text-muted mb-0">Average Degree</h5>
                      <span class="h2 font-weight-bold mb-0" id="stats_degree">350,897</span>
                      <p class="mt-3 mb-0 text-muted text-sm">
                        <span class="text-nowrap" id="stats_min_degree"> 3.48%</span>&nbsp;&nbsp;
                        <span class="text-nowrap" id="stats_max_degree">Since last month</span>
                      </p>
                    </div>
                    <div class="col-auto">
                      <!-- <div class="icon icon-shape bg-danger text-white rounded-circle shadow"> -->
                      <span class="material-symbols-outlined" style="font-size:40px;">
                        hub
                      </span> <!-- </div> -->
                    </div>

                  </div>

                </div>
              </div>
            </div>
            <div style="grid-row:3;grid-column:2;">

              <div class="card card-stats mb-4 mb-xl-0">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h5 class="card-title text-uppercase text-muted mb-0">Average Indegree</h5>
                      <span class="h2 font-weight-bold mb-0" id="stats_indegree">350,897</span>
                      <p class="mt-3 mb-0 text-muted text-sm">
                        <span class="text-nowrap" id="stats_min_indegree"> 3.48%</span>&nbsp;&nbsp;
                        <span class="text-nowrap" id="stats_max_indegree">Since last month</span>
                      </p>
                    </div>
                    <div class="col-auto">
                      <!-- <div class="icon icon-shape bg-danger text-white rounded-circle shadow"> -->
                      <span class="material-symbols-outlined" style="font-size:40px;">
                        step_into
                      </span> <!-- </div> -->
                    </div>

                  </div>

                </div>
              </div>
            </div>
            <div style="grid-row:3;grid-column:3;">

              <div class="card card-stats mb-4 mb-xl-0">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h5 class="card-title text-uppercase text-muted mb-0">Average Outdegree</h5>
                      <span class="h2 font-weight-bold mb-0" id="stats_outdegree">350,897</span>
                      <p class="mt-3 mb-0 text-muted text-sm">
                        <span class="text-nowrap" id="stats_min_outdegree"> 3.48%</span>&nbsp;&nbsp;
                        <span class="text-nowrap" id="stats_max_outdegree">Since last month</span>
                      </p>
                    </div>
                    <div class="col-auto">
                      <!-- <div class="icon icon-shape bg-danger text-white rounded-circle shadow"> -->
                      <span class="material-symbols-outlined" style="font-size:40px;">
                        step_out
                      </span> <!-- </div> -->
                    </div>

                  </div>

                </div>
              </div>
            </div>
            <div style="grid-row:4;grid-column:1;">

              <div class="card card-stats mb-4 mb-xl-0">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h5 class="card-title text-uppercase text-muted mb-0">Connected Components</h5>
                      <span class="h2 font-weight-bold mb-0" id="stats_conn_components">350,897</span>
                    </div>
                    <div class="col-auto">
                      <!-- <div class="icon icon-shape bg-danger text-white rounded-circle shadow"> -->
                      <span class="material-symbols-outlined" style="font-size:40px;">
                        tenancy
                      </span> <!-- </div> -->
                    </div>
                  </div>
                  <!-- <p class="mt-3 mb-0 text-muted text-sm">
                            <span class="text-success mr-2"><i class="fa fa-stroopwafel"></i> 3.48%</span>
                            <span class="text-nowrap">Since last month</span>
                          </p> -->
                </div>
              </div>
            </div>
            <div style="grid-row:4;grid-column:2;">

              <div class="card card-stats mb-4 mb-xl-0">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h5 class="card-title text-uppercase text-muted mb-0">Isolated Nodes</h5>
                      <span class="h2 font-weight-bold mb-0" id="stats_iso_nodes">350,897</span>
                    </div>
                    <div class="col-auto">
                      <!-- <div class="icon icon-shape bg-danger text-white rounded-circle shadow"> -->
                      <span class="material-symbols-outlined" style="font-size:40px;" e="">
                        spoke
                      </span> <!-- </div> -->
                    </div>
                  </div>
                  <!-- <p class="mt-3 mb-0 text-muted text-sm">
                              <span class="text-success mr-2"><i class="fa fa-stroopwafel"></i> 3.48%</span>
                              <span class="text-nowrap">Since last month</span>
                            </p> -->
                </div>
              </div>
            </div>
            <div *ngIf="net_stat_load_screen"
              style="grid-row:2/5;grid-column:1/4;display: flex;align-items: center;justify-content: center;z-index: 11;background-color: white;">
              <div>
                <span>Loading</span>&nbsp;&nbsp;
                <span id="net_stat_load_perc">{{net_stat_load_perc}}%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="main_network_selection"
      style="width:100%;z-index: 3;display: flex;justify-content: center;position: absolute;bottom:30px;left:0px;right: 0px;display: none;">
      <div style="height: 50px;width: 50%; display: flex;justify-content: space-around;">

        <div id="work" style="display: flex;align-items: center; cursor: pointer;   box-shadow: 0px 1px 1px 0px rgba(146, 146, 146, 0.75);
              border-radius: 10px; padding: 12px 15px;height:20px;" (click)="hideEdges('work')" class="border-2px">
          <div style="height:10px;width: 20px;background-color: #ec700b;"></div>
          <span style="font-size:17px; margin-left:2px;">Execution</span>
        </div>
        <div id="innovation" style="display: flex;align-items: center; cursor: pointer;box-shadow: 0px 1px 1px 0px rgba(146, 146, 146, 0.75);
              border-radius: 10px; padding: 12px 15px;height:20px;" (click)="hideEdges('innovation')"
          class="border-2px">
          <div style="height:10px;width: 20px; background-color:#0b47ec ;"></div>
          <span style="font-size:17px;margin-left:2px;">Ideation</span>
        </div>
        <div id="expertise" style="display: flex;align-items: center;cursor: pointer;box-shadow: 0px 1px 1px 0px rgba(146, 146, 146, 0.75);
              border-radius: 10px; padding: 12px 15px;height:20px;" (click)="hideEdges('expertise')"
          class="border-2px">
          <div style="height:10px;width: 20px; background-color:#8aec0b;"></div>
          <span style="font-size:17px;margin-left:2px;">Expertise</span>
        </div>
        <div id="trust" style="display: flex;align-items: center;cursor: pointer;box-shadow: 0px 1px 1px 0px rgba(146, 146, 146, 0.75);
              border-radius: 10px; padding: 12px 15px;height:20px;" (click)="hideEdges('trust')" class="border-2px">
          <div style="height:10px;width: 20px; background-color:#9a07c7;"></div>
          <span style="font-size:17px;margin-left:2px;">Trust</span>
        </div>
        <div id="energy" style="display: flex;align-items: center; cursor: pointer;box-shadow: 0px 1px 1px 0px rgba(146, 146, 146, 0.75);
              border-radius: 10px; padding: 12px 15px;height:20px;" (click)="hideEdges('energy')" class="border-2px">
          <div style="height:10px;width: 20px; background-color:#e7e41d;"></div>
          <span style="font-size:17px;margin-left:2px;">Energy</span>
        </div>


      </div>

    </div>
    <!-- </div> -->

    <div style="width:100%;padding:10px; font-size: 18px;text-align: center;background-color: rgb(235 235 235);z-index: 5;right:45%;margin: auto;-webkit-box-shadow: 0px 0px 9px 1px rgba(0,0,0,0.75);
      -moz-box-shadow: 0px 0px 9px 1px rgba(0,0,0,0.75);

      box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.75);" id="top_heading_container">
      <img src='assets/orglens.png' style="float:left;height: fit-content;margin-left:20px;border-radius:0%"
        alt="Orglens">
      <span id="graph_heading"></span>

      <span class="material-symbols-outlined" style="cursor: pointer; float: right;margin:0px 10px;    color: #005199;
      " title="Download the network as an image" (click)='downloadImage()' id="step1">
        download
      </span>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <span class="material-symbols-outlined" style="cursor: pointer; float: right;margin:0px 10px;    color: #027606;
      " title="Network Statistics" (click)='open_stats_modal()' id="step3">analytics</span>
      &nbsp;&nbsp;&nbsp;&nbsp;<button class="btn btn-primary" (click)="startSteps()"
        style="font-size: 11px;padding:1px 10px;cursor: pointer;float: right;margin-right: 10px;">Guided
        Tour</button>&nbsp;&nbsp;&nbsp;&nbsp;

    </div>
    <div id="notification-container">
      <div id="side_notification">
        <span id="notification-text"></span>
        <span id="close-button" class="material-symbols-outlined" (click)="closeNotification()">close</span>
      </div>
    </div>
    <div class="action" id="step2" (mouseenter)="show_sidenav()">

      <button class="settings" id="setting"><span class="material-symbols-outlined">settings</span></button>

    </div>
    <!-- <div style="height:fit-content;width: fit-content;background-color: #f0f6ff;border-radius:2rem;display: flex;flex-direction: column;
    position: absolute;right:30px;top:200px;z-index:100;padding:10px;-webkit-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);
box-shadow: 0px 0px 15px -6px rgba(0,0,0,0.75);">
      <div>
        <div title="Network Type" class="network_type_bar_icon" (click)="open_network_type_options()"
          style="margin:10px;"><span class="material-symbols-outlined"
            style="font-size:2rem;cursor: pointer;color:#97adc6">hub</span></div>
        <div class="container" id="network_options">
          <div class="item">

            <span>Combined</span>
          </div>
          <div class="item">
            <span>Work</span>
          </div>
          <div class="item">
            <span>Innovation</span>
          </div>
          <div class="item">
            <span>Expertise</span>
          </div>
          <div class="item">
            <span>Trust</span>
          </div>
          <div class="item">
            <span>Energy</span>
          </div>

        </div>
      </div>
      <div>
        <a title="Module Type" id="module_type_bar_icon" style="margin:10px;" (click)="open_module_type_options()"><span
          class="material-symbols-outlined" style="font-size:2rem;cursor: pointer;color:#97adc6">view_module</span></a>

        <div class="container" id="module_options">
          <div class="item">

            <span>Hubs</span>
          </div>
          <div class="item">
            <span>Pulsetaker</span>
          </div>
          <div class="item">
            <span>Gatekeeper</span>
          </div>
          <div class="item">
            <span>Influencer</span>
          </div>
          <div class="item">
            <span>Silo</span>
          </div>
          <div class="item">
            <span>Isolated</span>
          </div>

        </div>

      </div>
      <div>
        <a title="Edge Frequency" id="edge_frequency_bar_icon" style="margin:10px;"
        (click)="open_frequency_type_options()"><span class="material-symbols-outlined"
          style="font-size:2rem;cursor: pointer;color:#97adc6">multiple_stop</span></a>
          
        <div class="container" id="frequency_options">
          <div class="item">

            <span>Daily</span>
          </div>
          <div class="item">
            <span>Weekly</span>
          </div>
          <div class="item">
            <span>Monthly</span>
          </div>
          <div class="item">
            <span>Quarterly</span>
          </div>

        </div>
      </div>

   
    
    </div> -->

    <div
      style="height:0px;width:50px;display:flex;align-items:flex-end;;justify-content: end; position: absolute;right:30px;bottom:30px;z-index: 3;display:none; "
      id="options_pane_container">
      <div class="scrollbarcustom1" style="height:300px;width:0px;position: relative;left:30px;opacity:0;	visibility: hidden;
    background-color:  #f0f6ff;	box-shadow: 0px 5px 18px -2px rgba(0,0,0,0.7);overflow-y:scroll ;" id="options_pane">
        <p style="width:100%;text-align: center;margin:20px 0px 0px 0px;font-weight: bold;">Network</p>
        <div
          style="display:flex;flex-direction: column; justify-content: space-evenly; align-items: center;margin:5px 20px 20px; border: solid 1px #CFE2DD;padding:10px;">

          <div style="display:flex;justify-content: start; align-items: center;margin:10px 0px; ; width: 100%;">
            <button class="item network" style="background-color: #027606;color: white;"
              (click)="change_network('combined')" id="combined_element" value="combined">
              Combined
            </button>
            <button class="item network" (click)="change_network('work')" value="work" id="work_element">
              Work
            </button>
            <button class="item network" id="innovation_element" (click)="change_network('innovation')"
              value="innovation">
              Innovation
            </button>
            <button class="item network" id="expertise_element" (click)="change_network('expertise')" value="expertise">
              Expertise
            </button>
            <button class="item network" id="trust_element" (click)="change_network('trust')" value="trust">
              Trust

            </button>
            <button class="item network" id="energy_element" (click)="change_network('energy')" value="energy">
              Energy

            </button>

          </div>
          <!-- <div style="display:flex;justify-content: start; align-items: center;margin:10px 0px;; width: 100%;">

            
          </div> -->
        </div>
        <!-- <p style="width:100%;text-align: center;margin:20px 0px 0px 0px;font-weight: bold;">Highlight Individual</p>

        <div
          style="display:flex;flex-direction: column; justify-content: space-evenly; align-items: center;margin:5px 20px 20px;;border: solid 1px #CFE2DD;padding:10px;">
          <div style="display:flex;justify-content: start; align-items: center;margin:10px 0px; width: 100%;">
            <button class="item" (click)="change_module('hub')" value="hub" id="hub_element">
              Hub

            </button>
            <button class="item" (click)="change_module('pulse_taker')" value="pulse_taker" id="pulsetaker_element">
              Pulsetaker

            </button>
            <button class="item" (click)="change_module('gate_keeper')" value="gate_keeper" id="gatekeeper_element">
              Gatekeeper

            </button>
            <button class="item" (click)="change_module('influencer')" value="influencer" id="influencer_element">
              Influencer

            </button>
            <button class="item" (click)="change_module('silo')" value="silo" id="silo_element">
              Silo

            </button>
            <button class="item" (click)="change_module('disc_emp')" value="disc_emp" id="isolated_element">
              Isolated

            </button>
            <button class="item" (click)="change_module('power_players')" value="power_players"
              id="power_players_element">
              Power Players

            </button>
            <button class="item" (click)="change_module('none')" value="none" id="none_element"
              style="background-color: black;color: white;">
              None

            </button>

          </div>

         
        </div> -->
        <!-- <p style="width:100%;text-align: center;margin:20px 0px 0px 0px;font-weight: bold;">Connection Frequency and
          Reciprocity
          <span style="font-weight: 200;font-size: 12px;">(Frequency is not applicable for Combined Network)</span>
        </p>
        <div
          style="display:flex;flex-direction: column; justify-content: space-evenly; align-items: center;margin:5px 20px 20px;;border: solid 1px #CFE2DD;padding:10px;">
          <div style="display:flex;justify-content: space-evenly; align-items: center;margin:10px 0px; width: 100%;">
            <div class="">
              <input type="checkbox" id="daily" checked> &nbsp;&nbsp;
              <span>Daily</span>

            </div>
            <div class="">
              <input type="checkbox" id="weekly" checked>&nbsp;&nbsp;

              <span>Weekly</span>

            </div>
            <div class="">
              <input type="checkbox" id="monthly" checked>&nbsp;&nbsp;

              <span>Monthly</span>

            </div>
            <div class="">
              <input type="checkbox" id="quarterly" checked>&nbsp;&nbsp;

              <span>Quarterly</span>

            </div>

          </div>
          <div style="display:flex;justify-content: space-evenly; align-items: center;margin:10px 0px; width: 100%;">
            <div class="">
              <input type="checkbox" id="reciprocal_nodes">&nbsp;

              <span>Show Reciprocal Connections Only</span>

            </div>
          </div>
          <div style="display:flex;align-items: center;justify-content: center;">
            <button class="button-18" id="" (click)="reciprocal_frequency_apply()">Apply</button>
          </div>
        </div> -->
        <!-- <p style="width:100%;text-align: center;margin:20px 0px 0px 0px;font-weight: bold;">Connection Reciprocity
        </p>
        <div   
          style="display:flex;flex-direction: column; justify-content: space-evenly; align-items: center;margin:5px 20px 20px;;border: solid 1px #CFE2DD;padding:10px;">

          <div
            style="display:flex;justify-content: space-evenly; align-items: center;margin:10px 0px; width: 100%;">
            
            <div class="">
              <input type="checkbox" id="reciprocal_nodes">&nbsp;

              <span>Show Reciprocal Connections Only</span>

            </div>

          </div>
          <div style="display:flex;align-items: center;justify-content: center;">
            <button class="button-18" (click) = "reciprocal_connection_apply()">Apply</button>
          </div>
        </div> -->
        <!-- <div style="display:flex;align-items: center;justify-content: center;">
          <button class="button-18">Apply</button>
        </div> -->

      </div>
      <div style="height:50px;width:50px;border-radius: 50px;z-index: 4;	background-color:  #f0f6ff;display: flex;align-items: center;	
    box-shadow: 0px 5px 18px -2px rgba(0,0,0,0.7);justify-content: center; " id="open_options_pane"><span
          class="material-symbols-outlined" style="color:#a7bbb5">view_module</span></div>
    </div>



    <!-- <div class="container" id="network_options" style="bottom:200px;right:50px">
      <div class="item network_element_active" id="combined_element" value="combined"
        style="background-color:#027606;color:white;">
        <span>Combined</span>

      </div>
      <div class="item" value="work" id="work_element">
        <span>Work</span>

      </div>
      <div class="item" id="innovation_element" value="innovation">
        <span>Innovation</span>

      </div>
      <div class="item" id="expertise_element" value="expertise">
        <span>Expertise</span>

      </div>
      <div class="item" id="trust_element" value="trust">
        <span>Trust</span>

      </div>
      <div class="item" id="energy_element" value="energy">
        <span>Energy</span>

      </div>
      <div class="anchor" title="Select a type of network">
        <span class="material-symbols-outlined" style="font-size:2rem;cursor: pointer;color:#97adc6;  margin:0px  20px;
">hub</span>
      </div>
    </div>
    <div class="container" id="module_options" style="bottom:130px;right:50px">
      <div class="item module_element_active" value="hub" id="hub">
        <span>Hub</span>

      </div>
      <div class="item" value="pulsetaker" id="pulsetaker">
        <span>Pulsetaker</span>

      </div>
      <div class="item" value="gatekeeper" id="gatekeeper">
        <span>Gatekeeper</span>

      </div>
      <div class="item" value="influencer" id="influencer">
        <span>Influencer</span>

      </div>
      <div class="item" value="silo" id="silo">
        <span>Silo</span>

      </div>
      <div class="item" value="isolated" id="isolated">
        <span>Isolated</span>

      </div>
      <div class="anchor" title="Select a type of individual to highlight">
        <span class="material-symbols-outlined" style="font-size:2rem;cursor: pointer;color:#97adc6;  margin: 0px 20px;
">view_module</span>
      </div>
    </div>
    <div class="container" id="frequency_options" style="bottom:60px;right:50px">
      <div class="item">
        <input type="checkbox" checked> &nbsp;&nbsp;
        <span>Daily</span>

      </div>
      <div class="item">
        <input type="checkbox" checked>&nbsp;&nbsp;

        <span>Weekly</span>

      </div>
      <div class="item">
        <input type="checkbox" checked>&nbsp;&nbsp;

        <span>Monthly</span>

      </div>
      <div class="item">
        <input type="checkbox" checked>&nbsp;&nbsp;

        <span>Quarterly</span>

      </div>
      <div class="anchor" title="Select frequencies at which the nodes reach out to other nodes">
        <span class="material-symbols-outlined" style="font-size:2rem;cursor: pointer;color:#97adc6;  margin: 0px 20px;
">multiple_stop</span>
      </div>
    </div> -->



    <div id="panel_edit" class="sidenav" style="pointer-events: all;" (mouseleave)="hide_sidenav()">
      <div class="closebtn">
        <span style="text-align: start;font-size: 20px;">Load a Graph</span><span
          style="z-index: 5; cursor: pointer;color:white" class="material-symbols-outlined"
          (click)="hide_sidenav()">close</span>
      </div>
      <div id="top_panel_display"
        style="height: 55%;width: 95%; padding:30px ;z-index: 3;margin: 50px 10px 0px 10px;font-size: 15px; 
        position: absolute;right: 0px; display: grid;grid-template-columns: 40% 60%; grid-template-rows: repeat(6,1fr);row-gap: 10px;
        column-gap: 10px;flex-direction: column;align-items: flex-start;border: 1px solid white;border-radius:20px;
        background-color: rgb(7 34 68);-webkit-box-shadow: 0px 0px 10px 10px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 10px 10px rgba(0,0,0,0.75);box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.75);display:none;">
        <div id="edit_but" (click)="open_edit()"
          style="display:flex;justify-content: end;width: 100%;grid-column: 2;grid-row: 6;text-align: left;align-items: center;align-self:end;cursor: pointer;">
          Edit
          &nbsp;<span class="material-symbols-outlined"
            style="grid-column: 2;grid-row: 6;text-decoration: underline;cursor: pointer;color:white;">
            edit
          </span></div>
        <!-- <div id="display_team_type_dd_container" style=""> -->
        <span style="grid-column: 1;grid-row: 1;text-align: left;">Team Type&nbsp;:&nbsp;&nbsp;</span>
        <span id="display_team_type_dd" style="grid-column: 2;grid-row: 1;font-weight: bold;text-align: left;"></span>
        <!-- </div> -->

        <!-- <div id="display_team_dd_container" style=""> -->
        <span class="team_dd_caption"
          style="grid-column: 1;grid-row: 2;text-align: left;">Team&nbsp;:&nbsp;&nbsp;</span>
        <span id="display_team_dd" style="grid-column: 2;grid-row: 2;font-weight: bold;text-align: left;"></span>
        <!-- </div> -->

        <!-- <div id="display_network_dd_container" style=""> -->
        <span style="grid-column: 1;grid-row: 3;text-align: left;">Network&nbsp;:&nbsp;&nbsp;</span>
        <span id="display_network_dd" style="grid-column: 2;grid-row: 3;font-weight: bold;text-align: left;"></span>
        <!-- </div> -->

        <!-- <div id="display_node_size_by_dd_container" style=""> -->
        <span style="grid-column: 1;grid-row: 4;text-align: left;">Node Size by&nbsp;:&nbsp;&nbsp;</span>
        <span id="display_node_size_dd" style="grid-column: 2;grid-row: 4;font-weight: bold;text-align: left;"></span>
        <!-- </div> -->

        <!-- <div id="display_node_color_by_dd_container" style=""> -->
        <span style="grid-column: 1;grid-row: 5;text-align: left;">Node Color by&nbsp;:&nbsp;&nbsp;</span>
        <span id="display_node_color_dd" style="grid-column: 2;grid-row: 5;font-weight: bold;text-align: left;"></span>
        <!-- </div> -->


      </div>
      <section class="tabs-wrapper">
        <div class="tabs-container">
          <div class="tabs-block">
            <div class="tabs">
              <input type="radio" (click)="change_network_tab('general',0)" name="tabs" id="tab1" checked="checked" />
              <label for="tab1"><span class="material-icons"></span> General Networks</label>
              <div class="tab" >
                <div style="color: gray;" *ngIf="general_tab_active"
                  style="display: grid;grid-template-columns: 20% 30% 20% 30%; grid-template-rows: repeat(13,min-content); grid-row: 2/11;grid-column: 1/5;border: 0px 1px 1px 1px;padding: 10px;row-gap: 10px;column-gap: 10px;">

                  <span style="color: gray;text-align: start;grid-column: 1/3;grid-row: 2;font-size: 18px;padding:20px 0px;">Network
                    Options</span>

                  <span
                    style=" color: gray;text-align: start;grid-column: 1;grid-row: 3;display:flex;align-items: center;justify-content: space-between;">Team
                    Type&nbsp;:
                    <span class="material-symbols-outlined" style="font-size: 15px;cursor: pointer;"
                      data-tooltip="You can select a team type (eg. Organisation, Department etc)"
                      data-tooltip-location="top">help</span></span>
                  <select id="team_type_dd" (change)="change_Team_Type()"
                    style="width: 80%;grid-column: 2;grid-row: 3;"></select>

                  <span class="team_dd_caption"
                    style="color: gray; text-align: start;grid-column: 3;grid-row: 3;display: flex;align-items: center;justify-content: space-between;">Team&nbsp;:
                  </span>
                  <select id="team_dd" (change)="change_Team()" style="width: 80%;grid-column: 4;grid-row: 3;"></select>

                  <span
                    style="color: gray;text-align: start;grid-column: 1;grid-row: 4;display: flex;align-items: center;justify-content: space-between;">Network
                    &nbsp;:<span class="material-symbols-outlined" style="font-size: 15px;cursor: pointer;"
                      data-tooltip="You can select a network type (Combined , Execution/Work, Innovation/Ideation, Expertise, Trust, Energy)"
                      data-tooltip-location="top">help</span></span>
                  <select id="network_dd" (change)="change_network_dd()"
                    style="width: 80%;grid-column: 2;grid-row: 4;"></select>

                  <span
                    style="color: gray;text-align: start;grid-column: 3;grid-row: 4;display: flex;align-items: center;justify-content: space-between;">Layout&nbsp;:<span
                      class="material-symbols-outlined" style="font-size: 15px;cursor: pointer;" id="layout_dd_help"
                      data-tooltip="" data-tooltip-location="top">help</span>

                  </span>
                  <select id="layout_dd" style="width: 80%;grid-column: 4;grid-row: 4;"></select>
                  <span style="color: gray;text-align: start;grid-column: 1/3;grid-row: 5;font-size: 18px;padding:20px 0px;">Node
                    Options</span>

                  <span
                    style="color: gray;text-align: start;grid-column: 1;grid-row: 6;display: flex;align-items: center;justify-content: space-between;">Node
                    Color
                    by&nbsp;:<span class="material-symbols-outlined" style="font-size: 15px;cursor: pointer;"
                      data-tooltip="You can color the nodes according to this selection"
                      data-tooltip-location="top">help</span></span>
                  <select id="node_color_dd" style="width: 80%;grid-column: 2;grid-row: 6;"
                    (change)="change_node_color_option()"></select>


                  <span
                    style="color: gray;text-align: start;grid-column: 3;grid-row: 6;display: flex;align-items: center;justify-content: space-between;">Node
                    Size
                    by&nbsp;:<span class="material-symbols-outlined" style="font-size: 15px;cursor: pointer;"
                      data-tooltip="You can size the nodes according to this selection"
                      data-tooltip-location="top">help</span></span>
                  <select id="node_size_dd" style="width: 80%;grid-column: 4;grid-row: 6;"></select>

                  <span
                    style="color: gray;text-align: start;grid-column: 1;grid-row: 7;display: flex;align-items: center;justify-content: space-between;">Node
                    Label
                    by&nbsp;:<span class="material-symbols-outlined" style="font-size: 15px;cursor: pointer;"
                      data-tooltip="Label the nodes according to this" data-tooltip-location="top">help</span></span>
                  <select id="node_label_dd" style="width: 80%;grid-column: 2;grid-row: 7;"></select>
                  <span
                    style="grid-column: 3;grid-row: 7;align-self: center;display: flex;justify-content: space-between;align-items: center;">
                    <span style="width: 70%;color: gray;">Label Size&nbsp;</span><span class="material-symbols-outlined"
                      style="font-size: 15px;cursor:pointer;align-self: center;color: gray;"
                      data-tooltip="Change the node label size"
                      data-tooltip-location="top">help</span></span>
                  <div style="grid-column: 4 ;grid-row: 7;display: flex;justify-content: center;align-items: center;">
                    <button style="height: 1em; width: 1em;display: flex;justify-content: center;align-items: center;border: gray;"
                      (click)="change_label_size('increment')"><span
                        class="material-symbols-outlined">add</span></button>
                        <input type="text"  #labelsize  style="width: 30px;margin: 0px 10px;height: 1.5em;" (change)="onLabelSizeInputChange(labelsize.value)" (keydown)="onlyAllowNumbers($event)" placeholder="Enter numbers only" />
                    <button style="height: 1em; width: 1em;display: flex;justify-content: center;align-items: center;border: gray;"
                      (click)="change_label_size('decrement')"><span
                        class="material-symbols-outlined">remove</span></button>

                  </div>
                  <span style="color: gray;text-align: start;grid-column: 1/3;grid-row: 8;font-size: 18px;padding:10px 0px;">Edge
                    Options <span style="font-weight: 200;font-size: 12px;cursor: pointer;" class="material-symbols-outlined"
                    data-tooltip=" Frequency is not applicable for Combined/Professional/Personal Network options. However you can view the frequency for other Network Type options."
                    data-tooltip-location="top">info</span></span>
                  <div style="text-align: start;grid-column: 1/5;grid-row: 9;color:rgb(119, 118, 118);">

                    <!-- <p style="width:100%;text-align: center;margin:0px 0px 0px 0px;font-weight: bold;">Connection
                      Frequency and
                      Reciprocity
                     
                    </p> -->

                    <div
                      style="display:flex;flex-direction: column; justify-content: start; align-items: center;margin:0px 20px 20px;">
                      <div style="display:flex;justify-content: start;width: 100%;">

                        <div
                          style="display:flex;justify-content: start; align-items: center;margin:10px 0px;  width: 50%; ">
                          <div style="width: 50%;">
                            <input type="checkbox" id="daily" checked> &nbsp;&nbsp;
                            <span style="color:rgb(119, 118, 118);">Daily</span>

                          </div>
                          <div style="width: 50%;">
                            <input type="checkbox" id="weekly" checked>&nbsp;&nbsp;

                            <span style="color:rgb(119, 118, 118);">Weekly</span>

                          </div>


                        </div>
                        <div
                          style="display:flex;justify-content: start; align-items: center;margin:10px 0px; width: 50%; ">

                          <div style="width: 50%;">
                            <input type="checkbox" id="monthly" checked>&nbsp;&nbsp;

                            <span style="color:rgb(119, 118, 118);">Monthly</span>

                          </div>
                          <div style="width: 50%;">
                            <input type="checkbox" id="quarterly" checked>&nbsp;&nbsp;

                            <span style="color:rgb(119, 118, 118);">Quarterly</span>

                          </div>

                        </div>
                      </div>
                      <div style="display:flex;justify-content: start; width: 100%;">
                        <div
                          style="display:flex;justify-content: start; align-items: center;margin:10px 0px; width: 50%;">
                          <div>
                            <input type="checkbox" id="reciprocal_nodes">&nbsp;

                            <span style="color:rgb(119, 118, 118);">Show Reciprocal Connections Only</span>


                          </div>


                        </div>
                        <div
                          style="display:flex;justify-content: start; align-items: center;margin:10px 0px; width: 50%;">

                          <div>
                            <input id="connection_frequency_label_check" type="checkbox"
                              style="position: inherit;height: min-content;align-self: center;" />&nbsp;
                            <span style="width: 70%;color:rgb(119, 118, 118);">Show frequency</span>
                            
                          </div>

                        </div>
                      </div>
                      <!-- <div style="display:flex;align-items: center;justify-content: end;width: 100%;">
                      <button class="button-18-2" id="apply_edge_settings" (click)="reciprocal_frequency_apply()">Apply Settings</button>
                    </div> -->
                    </div>
                  </div>

                  <div id="advanced_settings_container_parent"
                    style="text-align: start;grid-column: 1/5;grid-row: 28;font-size: 18px;margin:0px 10px 0px 0px; display: flex;flex-direction: column;color:rgb(119, 118, 118);">
                    <span
                      style="cursor:pointer;border: 1px solid #002a57;padding: 10px;margin:10px 0px; display:flex;justify-content: space-between;align-items: center;"
                      (click)="open_close_advanced_settings()"><span>Advanced Settings</span>

                      <span style="z-index: 5; cursor: pointer;color:rgb(0, 45, 112);" class="material-symbols-outlined"
                        id="advanced_setting_arrow" (click)="hide_sidenav()">keyboard_arrow_down</span>




                    </span>

                    <div style="display:none;" id="advanced_settings_container">
                      <div style="display: grid;grid-template-columns: 20% 30% 20% 30%; grid-template-rows: repeat(10,min-content);
                      row-gap: 10px;column-gap: 10px;height: 100%;width: 100%;
                      font-size: 15px; margin-top: 20px;color:rgb(119, 118, 118); ">
                        <span
                          style="grid-column: 1/3;grid-row: 1;align-self: center;display: flex;justify-content: space-between;align-items: center;">
                          <span style="text-align: start;width: 70%;">Show nodes according to&nbsp;:
                          </span>
                          <span class="material-symbols-outlined"
                            style="font-size: 15px;cursor:pointer;align-self: center;"
                            data-tooltip="You can load the nodes according to these options and control the values using the node count slider"
                            data-tooltip-location="top">help</span>
                        </span>
                        <select id="nodes_sort_advance"
                          style="width: 80%;grid-column: 3/5;grid-row: 1;height: min-content;align-self: center;"
                          disabled>
                          <option value="value">Random</option>

                          <!-- <option value="k-core">Minimum connections per employee</option> -->



                        </select>
                        <span
                          style="grid-column: 1/3;grid-row: 2;align-self: center;display: flex;justify-content: space-between;align-items: center;">
                          <span style="width: 70%;">Show groups as a single node&nbsp;:</span>
                          <span class="material-symbols-outlined" style="font-size: 15px;cursor:pointer;"
                            data-tooltip="You can see a team as a single node (when the box is checked) or as a group of  individuals. Useful when 'Team Type' is selected as Organization and then selecting 'Group by'."
                            data-tooltip-location="top">help</span>
                        </span><input id="group_as_single_node_check" type="checkbox"
                          (change)="change_showGroupAsSingleNode()"
                          style="grid-column: 3/5;grid-row: 2;position: inherit;height: min-content;align-self: center;"
                          checked />

                        <span
                          style="grid-column: 1/3;grid-row: 3;align-self: center;display: flex;justify-content: space-between;align-items: center;">
                          <span style="width: 70%;">Isolated nodes&nbsp;</span><span class="material-symbols-outlined"
                            style="font-size: 15px;cursor:pointer;align-self: center;"
                            data-tooltip="See the nodes that are disconnected from the network"
                            data-tooltip-location="top">help</span></span><input id="d_s_iso_nodes_check"
                          type="checkbox" (change)="change_dsIsoNodes()"
                          style="grid-column: 3/5;grid-row: 3;position: inherit;height: min-content;align-self: center;"
                          checked />


                        <!-- <input id="d_s_iso_nodes_check" type="checkbox"
                        (change)="change_dsIsoNodes()"
                        style="grid-column: 4;grid-row: 3;position: inherit;height: min-content;align-self: center;" checked /> -->


                        <span
                          style="grid-column: 1/3;grid-row: 4;align-self: center;display: flex;justify-content: space-between;align-items: center;"><span
                            style="width: 70%;">Show leadership team only&nbsp;:</span><span
                            class="material-symbols-outlined" style="font-size: 15px;cursor:pointer;"
                            data-tooltip="See the leadership team of the selected network graph"
                            data-tooltip-location="top">help</span></span><input id="leadership_team" type="checkbox"
                          style="grid-column: 3/5;grid-row: 4;position: inherit;height: min-content;align-self: center;" />
                        <span
                          style="grid-column: 1/3;grid-row: 5;align-self: center;display: flex;justify-content: space-between;align-items: center;"><span
                            style="width: 70%;">Show connections between two specific
                            teams&nbsp;:</span><span class="material-symbols-outlined"
                            style="font-size: 15px;cursor:pointer;"
                            data-tooltip="See connections between two specific teams"
                            data-tooltip-location="top">help</span></span><input id="two_specific_entities_check"
                          (change)="change_specificEntities_checkBox()" type="checkbox"
                          style="grid-column: 3/5;grid-row: 5;position: inherit;height: min-content;align-self: center;" />
                        <span
                          style="grid-column: 1/3;grid-row: 6;align-self: center;display: flex;justify-content: space-between;align-items: center;"
                          class="two_specific_teams_options_class"><span style="width: 70%;">Team
                            type&nbsp;:</span><span class="material-symbols-outlined"
                            style="font-size: 15px;cursor:pointer;"
                            data-tooltip="Select Team Type of the two teams you want to see connections between"
                            data-tooltip-location="top">help</span></span> <select id="team_type_2"
                          class="two_specific_teams_options_class" (change)="change_Advanced_Team_Type()"
                          style="width: 80%;grid-column: 3/5;grid-row: 6;align-self: center;" disabled></select>
                        <span class="two_specific_teams_options_class"
                          style="grid-column: 1/3;grid-row: 7;align-self: center;display: flex;justify-content: space-between;align-items: center;"><span
                            style="width: 70%;">Team
                            1&nbsp;:</span><span class="material-symbols-outlined"
                            style="font-size: 15px;cursor:pointer;" data-tooltip="Select your first team"
                            data-tooltip-location="top">help</span></span> <select id="team_dd_1"
                          class="two_specific_teams_options_class"
                          style="width: 80%;grid-column: 3/5;grid-row: 7;height: min-content;align-self: center;"
                          disabled></select>

                        <span
                          style="grid-column: 1/3;grid-row: 8;align-self: center;display: flex;justify-content: space-between;align-items: center;"
                          class="two_specific_teams_options_class"><span style="width: 70%;">Team
                            2&nbsp;:</span><span class="material-symbols-outlined"
                            style="font-size: 15px;cursor:pointer;" data-tooltip="Select your second team"
                            data-tooltip-location="top">help</span></span> <select id="team_dd_2"
                          class="two_specific_teams_options_class"
                          style="width: 80%;grid-column: 3/5;grid-row: 8;height: min-content;align-self: center;"
                          disabled></select>
                         
                      </div>


                    </div>

                  </div>


                </div>
              </div>

              <input type="radio" (click)="change_network_tab('teams',0)" name="tabs" id="tab2" />
              <label for="tab2"><span class="material-icons"></span> Teams</label>
              <div class="tab" >
                <div style="color: gray;" *ngIf="teams_tab_active"
                  style="display: grid;grid-template-columns: 20% 30% 20% 30%; grid-template-rows: repeat(13,min-content); grid-row: 2/11;grid-column: 1/5;border: 0px 1px 1px 1px;padding: 10px;row-gap: 10px;column-gap: 10px;">

                  <span style="color: gray;text-align: start;grid-column: 1/3;grid-row: 2;font-size: 18px;padding:20px 0px;">Network
                    Options</span>

                  <span
                    style=" color: gray;text-align: start;grid-column: 1;grid-row: 3;display:flex;align-items: center;justify-content: space-between;">Team
                    Type&nbsp;:
                    <span class="material-symbols-outlined" style="font-size: 15px;cursor: pointer;"
                      data-tooltip="You can select a team type (eg. Organisation, Department etc)"
                      data-tooltip-location="top">help</span></span>
                  <select id="team_type_dd" (change)="change_Team_Type()"
                    style="width: 80%;grid-column: 2;grid-row: 3;"></select>

                  <span class="team_dd_caption"
                    style="color: gray; text-align: start;grid-column: 3;grid-row: 3;display: flex;align-items: center;justify-content: space-between;">Team&nbsp;:
                  </span>
                  <select id="team_dd" (change)="change_Team()" style="width: 80%;grid-column: 4;grid-row: 3;"></select>

                  <span
                    style="color: gray;text-align: start;grid-column: 1;grid-row: 4;display: flex;align-items: center;justify-content: space-between;">Network
                    &nbsp;:<span class="material-symbols-outlined" style="font-size: 15px;cursor: pointer;"
                      data-tooltip="You can select a network type (Combined , Execution/Work, Innovation/Ideation, Expertise, Trust, Energy)"
                      data-tooltip-location="top">help</span></span>
                  <select id="network_dd" (change)="change_network_dd()"
                    style="width: 80%;grid-column: 2;grid-row: 4;"></select>

                  <span
                    style="color: gray;text-align: start;grid-column: 3;grid-row: 4;display: flex;align-items: center;justify-content: space-between;">Layout&nbsp;:<span
                      class="material-symbols-outlined" style="font-size: 15px;cursor: pointer;" id="layout_dd_help"
                      data-tooltip="" data-tooltip-location="top">help</span>

                  </span>
                  <select id="layout_dd" style="width: 80%;grid-column: 4;grid-row: 4;"></select>
                  <span style="color: gray;text-align: start;grid-column: 1/3;grid-row: 5;font-size: 18px;padding:20px 0px;">Node
                    Options</span>

                  <span
                    style="color: gray;text-align: start;grid-column: 1;grid-row: 6;display: flex;align-items: center;justify-content: space-between;">Node
                    Color
                    by&nbsp;:<span class="material-symbols-outlined" style="font-size: 15px;cursor: pointer;"
                      data-tooltip="You can color the nodes according to this selection"
                      data-tooltip-location="top">help</span></span>
                  <select id="node_color_dd" style="width: 80%;grid-column: 2;grid-row: 6;"
                    (change)="change_node_color_option()"></select>


                  <span
                    style="color: gray;text-align: start;grid-column: 3;grid-row: 6;display: flex;align-items: center;justify-content: space-between;">Node
                    Size
                    by&nbsp;:<span class="material-symbols-outlined" style="font-size: 15px;cursor: pointer;"
                      data-tooltip="You can size the nodes according to this selection"
                      data-tooltip-location="top">help</span></span>
                  <select id="node_size_dd" style="width: 80%;grid-column: 4;grid-row: 6;"></select>

                  <span
                    style="color: gray;text-align: start;grid-column: 1;grid-row: 7;display: flex;align-items: center;justify-content: space-between;">Node
                    Label
                    by&nbsp;:<span class="material-symbols-outlined" style="font-size: 15px;cursor: pointer;"
                      data-tooltip="Label the nodes according to this" data-tooltip-location="top">help</span></span>
                  <select id="node_label_dd" style="width: 80%;grid-column: 2;grid-row: 7;"></select>
                  <span
                    style="grid-column: 3;grid-row: 7;align-self: center;display: flex;justify-content: space-between;align-items: center;">
                    <span style="width: 70%;color: gray;">Label Size&nbsp;</span><span class="material-symbols-outlined"
                      style="font-size: 15px;cursor:pointer;align-self: center;color: gray;"
                      data-tooltip="Change the node label size"
                      data-tooltip-location="top">help</span></span>
                  <div style="grid-column: 4 ;grid-row: 7;display: flex;justify-content: center;align-items: center;">
                    <button style="height: 1em; width: 1em;display: flex;justify-content: center;align-items: center;border: gray;"
                      (click)="change_label_size('increment')"><span
                        class="material-symbols-outlined">add</span></button>
                        <input type="text"  #labelsize style="width: 30px;margin: 0px 10px;height: 1.5em;"(change)="onLabelSizeInputChange(labelsize.value)" (keydown)="onlyAllowNumbers($event)" placeholder="Enter numbers only" />
                    <button style="height: 1em; width: 1em;display: flex;justify-content: center;align-items: center;border: gray;"
                      (click)="change_label_size('decrement')"><span
                        class="material-symbols-outlined">remove</span></button>

                  </div>
                  <span style="color: gray;text-align: start;grid-column: 1/3;grid-row: 8;font-size: 18px;padding:10px 0px;">Edge
                    Options <span style="font-weight: 200;font-size: 12px;cursor: pointer;" class="material-symbols-outlined"
                    data-tooltip=" Frequency is not applicable for Combined/Professional/Personal Network options. However you can view the frequency for other Network Type options."
                    data-tooltip-location="top">info</span></span>
                  <div style="text-align: start;grid-column: 1/5;grid-row: 9;color:rgb(119, 118, 118);">

                    <!-- <p style="width:100%;text-align: center;margin:0px 0px 0px 0px;font-weight: bold;">Connection
                      Frequency and
                      Reciprocity
                     
                    </p> -->

                    <div
                      style="display:flex;flex-direction: column; justify-content: start; align-items: center;margin:0px 20px 20px;">
                      <div style="display:flex;justify-content: start;width: 100%;">

                        <div
                          style="display:flex;justify-content: start; align-items: center;margin:10px 0px;  width: 50%; ">
                          <div style="width: 50%;">
                            <input type="checkbox" id="daily" checked> &nbsp;&nbsp;
                            <span style="color:rgb(119, 118, 118);">Daily</span>

                          </div>
                          <div style="width: 50%;">
                            <input type="checkbox" id="weekly" checked>&nbsp;&nbsp;

                            <span style="color:rgb(119, 118, 118);">Weekly</span>

                          </div>


                        </div>
                        <div
                          style="display:flex;justify-content: start; align-items: center;margin:10px 0px; width: 50%; ">

                          <div style="width: 50%;">
                            <input type="checkbox" id="monthly" checked>&nbsp;&nbsp;

                            <span style="color:rgb(119, 118, 118);">Monthly</span>

                          </div>
                          <div style="width: 50%;">
                            <input type="checkbox" id="quarterly" checked>&nbsp;&nbsp;

                            <span style="color:rgb(119, 118, 118);">Quarterly</span>

                          </div>

                        </div>
                      </div>
                      <div style="display:flex;justify-content: start; width: 100%;">
                        <div
                          style="display:flex;justify-content: start; align-items: center;margin:10px 0px; width: 50%;">
                          <div>
                            <input type="checkbox" id="reciprocal_nodes">&nbsp;

                            <span style="color:rgb(119, 118, 118);">Show Reciprocal Connections Only</span>


                          </div>


                        </div>
                        <div
                          style="display:flex;justify-content: start; align-items: center;margin:10px 0px; width: 50%;">

                          <div>
                            <input id="connection_frequency_label_check" type="checkbox"
                              style="position: inherit;height: min-content;align-self: center;" />&nbsp;
                            <span style="width: 70%;color:rgb(119, 118, 118);">Show frequency</span>
                            
                          </div>

                        </div>
                      </div>
                      <!-- <div style="display:flex;align-items: center;justify-content: end;width: 100%;">
                      <button class="button-18-2" id="apply_edge_settings" (click)="reciprocal_frequency_apply()">Apply Settings</button>
                    </div> -->
                    </div>
                  </div>

                 


                </div>
              </div>

              <input type="radio" (click)="change_network_tab('individual',0)" name="tabs" id="tab3" />
              <label for="tab3"><span class="material-icons"></span> Individual Network</label>
              <div class="tab" >
                <div style="color: gray;" *ngIf="individual_tab_active"
                  style="display: grid;grid-template-columns: 20% 30% 20% 30%; grid-template-rows: repeat(13,min-content); grid-row: 2/11;grid-column: 1/5;border: 0px 1px 1px 1px;padding: 10px;row-gap: 10px;column-gap: 10px;">

                  <span style="color: gray;text-align: start;grid-column: 1/3;grid-row: 2;font-size: 18px;padding:20px 0px;">Network
                    Options</span>

                  <span
                    style=" color: gray;text-align: start;grid-column: 1;grid-row: 3;display:flex;align-items: center;justify-content: space-between;">Team
                    Type&nbsp;:
                    <span class="material-symbols-outlined" style="font-size: 15px;cursor: pointer;"
                      data-tooltip="You can select a team type (eg. Organisation, Department etc)"
                      data-tooltip-location="top">help</span></span>
                  <select id="team_type_dd" (change)="change_Team_Type()"
                    style="width: 80%;grid-column: 2;grid-row: 3;"></select>

                  <span class="team_dd_caption"
                    style="color: gray; text-align: start;grid-column: 3;grid-row: 3;display: flex;align-items: center;justify-content: space-between;">Team&nbsp;:
                  </span>
                  <select id="team_dd" (change)="change_Team()" style="width: 80%;grid-column: 4;grid-row: 3;"></select>

                  <span
                    style="color: gray;text-align: start;grid-column: 1;grid-row: 4;display: flex;align-items: center;justify-content: space-between;">Network
                    &nbsp;:<span class="material-symbols-outlined" style="font-size: 15px;cursor: pointer;"
                      data-tooltip="You can select a network type (Combined , Execution/Work, Innovation/Ideation, Expertise, Trust, Energy)"
                      data-tooltip-location="top">help</span></span>
                  <select id="network_dd" (change)="change_network_dd()"
                    style="width: 80%;grid-column: 2;grid-row: 4;"></select>

                  <span
                    style="color: gray;text-align: start;grid-column: 3;grid-row: 4;display: flex;align-items: center;justify-content: space-between;">Layout&nbsp;:<span
                      class="material-symbols-outlined" style="font-size: 15px;cursor: pointer;" id="layout_dd_help"
                      data-tooltip="" data-tooltip-location="top">help</span>

                  </span>
                  <select id="layout_dd" style="width: 80%;grid-column: 4;grid-row: 4;"></select>
                  <span style="color: gray;text-align: start;grid-column: 1/3;grid-row: 5;font-size: 18px;padding:20px 0px;">Node
                    Options</span>

                  <span
                    style="color: gray;text-align: start;grid-column: 1;grid-row: 6;display: flex;align-items: center;justify-content: space-between;">Node
                    Color
                    by&nbsp;:<span class="material-symbols-outlined" style="font-size: 15px;cursor: pointer;"
                      data-tooltip="You can color the nodes according to this selection"
                      data-tooltip-location="top">help</span></span>
                  <select id="node_color_dd" style="width: 80%;grid-column: 2;grid-row: 6;"
                    (change)="change_node_color_option()"></select>


                  <span
                    style="color: gray;text-align: start;grid-column: 3;grid-row: 6;display: flex;align-items: center;justify-content: space-between;">Node
                    Size
                    by&nbsp;:<span class="material-symbols-outlined" style="font-size: 15px;cursor: pointer;"
                      data-tooltip="You can size the nodes according to this selection"
                      data-tooltip-location="top">help</span></span>
                  <select id="node_size_dd" style="width: 80%;grid-column: 4;grid-row: 6;"></select>

                  <span
                    style="color: gray;text-align: start;grid-column: 1;grid-row: 7;display: flex;align-items: center;justify-content: space-between;">Node
                    Label
                    by&nbsp;:<span class="material-symbols-outlined" style="font-size: 15px;cursor: pointer;"
                      data-tooltip="Label the nodes according to this" data-tooltip-location="top">help</span></span>
                  <select id="node_label_dd" style="width: 80%;grid-column: 2;grid-row: 7;"></select>
                  <span
                    style="grid-column: 3;grid-row: 7;align-self: center;display: flex;justify-content: space-between;align-items: center;">
                    <span style="width: 70%;color: gray;">Label Size&nbsp;</span><span class="material-symbols-outlined"
                      style="font-size: 15px;cursor:pointer;align-self: center;color: gray;"
                      data-tooltip="Change the node label size"
                      data-tooltip-location="top">help</span></span>
                  <div style="grid-column: 4 ;grid-row: 7;display: flex;justify-content: center;align-items: center;">
                    <button style="height: 1em; width: 1em;display: flex;justify-content: center;align-items: center;border: gray;"
                      (click)="change_label_size('increment')"><span
                        class="material-symbols-outlined">add</span></button>
                        <input type="text"  #labelsize style="width: 30px;margin: 0px 10px;height: 1.5em;"(change)="onLabelSizeInputChange(labelsize.value)" (keydown)="onlyAllowNumbers($event)" placeholder="Enter numbers only" />

                        <!-- <input type="text" style="width: 30px;margin: 0px 10px; height: 1em;" (change) = 'change_label_size()'/> -->
                    <button style="height: 1em; width: 1em;display: flex;justify-content: center;align-items: center;border: gray;"
                      (click)="change_label_size('decrement')"><span
                        class="material-symbols-outlined">remove</span></button>

                  </div>
                  <span style="color: gray;text-align: start;grid-column: 1/3;grid-row: 8;font-size: 18px;padding:10px 0px;">Edge
                    Options <span style="font-weight: 200;font-size: 12px;cursor: pointer;" class="material-symbols-outlined"
                    data-tooltip=" Frequency is not applicable for Combined/Professional/Personal Network options. However you can view the frequency for other Network Type options."
                    data-tooltip-location="top">info</span></span>
                  <div style="text-align: start;grid-column: 1/5;grid-row: 9;color:rgb(119, 118, 118);">

                    <!-- <p style="width:100%;text-align: center;margin:0px 0px 0px 0px;font-weight: bold;">Connection
                      Frequency and
                      Reciprocity
                     
                    </p> -->

                    <div
                      style="display:flex;flex-direction: column; justify-content: start; align-items: center;margin:0px 20px 20px;">
                      <div style="display:flex;justify-content: start;width: 100%;">

                        <div
                          style="display:flex;justify-content: start; align-items: center;margin:10px 0px;  width: 50%; ">
                          <div style="width: 50%;">
                            <input type="checkbox" id="daily" checked> &nbsp;&nbsp;
                            <span style="color:rgb(119, 118, 118);">Daily</span>

                          </div>
                          <div style="width: 50%;">
                            <input type="checkbox" id="weekly" checked>&nbsp;&nbsp;

                            <span style="color:rgb(119, 118, 118);">Weekly</span>

                          </div>


                        </div>
                        <div
                          style="display:flex;justify-content: start; align-items: center;margin:10px 0px; width: 50%; ">

                          <div style="width: 50%;">
                            <input type="checkbox" id="monthly" checked>&nbsp;&nbsp;

                            <span style="color:rgb(119, 118, 118);">Monthly</span>

                          </div>
                          <div style="width: 50%;">
                            <input type="checkbox" id="quarterly" checked>&nbsp;&nbsp;

                            <span style="color:rgb(119, 118, 118);">Quarterly</span>

                          </div>

                        </div>
                      </div>
                      <div style="display:flex;justify-content: start; width: 100%;">
                        <div
                          style="display:flex;justify-content: start; align-items: center;margin:10px 0px; width: 50%;">
                          <div>
                            <input type="checkbox" id="reciprocal_nodes">&nbsp;

                            <span style="color:rgb(119, 118, 118);">Show Reciprocal Connections Only</span>


                          </div>


                        </div>
                        <div
                          style="display:flex;justify-content: start; align-items: center;margin:10px 0px; width: 50%;">

                          <div>
                            <input id="connection_frequency_label_check" type="checkbox"
                              style="position: inherit;height: min-content;align-self: center;" />&nbsp;
                            <span style="width: 70%;color:rgb(119, 118, 118);">Show frequency</span>
                            
                          </div>

                        </div>
                      </div>
                      <!-- <div style="display:flex;align-items: center;justify-content: end;width: 100%;">
                      <button class="button-18-2" id="apply_edge_settings" (click)="reciprocal_frequency_apply()">Apply Settings</button>
                    </div> -->
                    </div>
                  </div>

               


                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <div style="width: 100%;display: flex;align-items: center;justify-content: center;">
      <button class="button-18" style="margin:20px;align-self: center;margin: auto;"
      role="button" id="generate_graph_button" (click)="start_graph_generation()">Generate Graph &nbsp; <span
        class="material-symbols-outlined">
        insights
      </span></button>
    </div>
    <div style="width: 100%;display: flex;align-items: center;justify-content: start;padding: 0px 20px;">
      <a style="grid-row:12;grid-column:1/3;cursor: pointer;" (click)="open_cag_modal()"><span>Create a new
        Network</span><span class="material-symbols-outlined">open_in_new</span></a>

</div>
   

    </div>
    <div id="_nodeInfo"
      style="bottom:10px;max-width: 400px;z-index: 10; flex-direction:column;align-items: start; position: absolute;left:20px;font-size: 12px;color: white;background-color: black;opacity: q;padding:20px;display: none;">
    </div>

    <div style="position: absolute;right: 10px;margin-top: 10px; display: flex; font-size: 15px;z-index: 2;"
      id="nodeSliderCont">
      <span style="margin-right:5px;" id="node_count_caption"> Minimum connections per employee : </span>
      <input type="range" min="0" max="" value="" id="_nodeSlider" (change)="node_slider_change()">
      <span id="_nodeCount"></span>/<span id="_maxNodeCount"></span>
    </div>
    <!-- <div><span
        style="width: 30px;position: absolute;z-index: 2;top:110px;right:10px;background-color: #cfd8dc;padding: 10px 10px 10px;border-radius: 10px;cursor: pointer;font-size: 25px;display: none;"
        id="open_popup" class="bounce" (click)="hide_show_popup('side-popup-seconddiv')">&lt;</span></div> -->

    <div id="side-popup-seconddiv"
      style="width: 350px;position: absolute;z-index: 1000;top:110px;right:10px;background-color: #cfd8dc;padding: 10px 10px 20px;border-radius: 10px;display: none;">

      <span style="position:relative;float:right;cursor: pointer;"
        (click)="hide_show_popup('side-popup-seconddiv')">X</span>
      <div style="position: relative;margin-top:20px;">
        <span style="margin-bottom: 30px; margin-top: 50px;">Things you can explore in this graph tool:</span>
        <div>
          <ol
            style="position:relative;background-color: #cfd8dc;color: black;text-align: left;list-style-type: circle;margin:10px 10px 0px 10px;">
            <li style="text-align: left;">Zoom in and out or reset to default zoom by using your mouse or by using the
              buttons in the top-right corner.</li>
            <li style="text-align: left;text-decoration: none;">Click and drag anywhere on the canvas (screen) to move
              the graph as whole.</li>
            <li style="text-align: left;">Click on an employee to view the person's details and network.</li>
            <li style="text-align: left;">To move an employee, click and drag the employee.</li>
            <li style="text-align: left;">Use the "Filter out" option in the bottom left to filter employees.</li>
            <li style="text-align: left;">If you have selected an employee, you can further filter his network according
              to the bottom left "Filter out" options</li>


          </ol>
        </div>
      </div>
    </div>
    <div id="dragbar" (click)="reSize()"></div>
    <img *ngIf="loading" id="spinner" src="assets/Infinity-1s-251px.svg">
    <!-- <img *ngIf="grphLoaded" id="spread" src="assets/spread.svg" (click)="spreadLayout()" data-toggle="tooltip"
      title="spread layout"> -->
    <div id="parent"></div>
    <!--  *ngIf="show_notification" -->
    <div id="notification" class="hide">

    </div>

    <!-- <span id="notification" class="hide"></span> -->

    <!-- https://www.flaticon.com/authors/freepik icon author -->




    <!-- <div style="z-index:2;position: absolute;border:1px solid red;">

    </div> -->



    <div id='cy'></div>
    <!-- <div
    style="position:absolute;left:20px;top:45%;height:1.5em;width:1.5em;border-radius:50% ;background-color: red;z-index:2;cursor:pointer;"
    (mouseover)="show_deleted_nodes_list()" (mouseleave)="hide_deleted_nodes_list()"></div>
  <div style="position:absolute;left:40px;top:45%;height:min-content;width:min-content;z-index:2;display:none;"
    id="deleted_nodes_list_container" (mouseover)="show_deleted_nodes_list()" (mouseleave)="hide_deleted_nodes_list()">
    <div style="display:flex;flex-direction: column;">
      <span>Restore Employee</span>
      <div id="deleted_nodes_list">

      </div>
    </div>
  </div> -->

    <div
      style=" position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%); display: flex;align-items: center;z-index: 999;"
      id="loadingicon">
      <span style="font-size: large;">Loading &nbsp;&nbsp;</span>
      <div class="lds-hourglass"></div>
    </div>
    <div
      style="position:absolute; bottom:20px; left:20px;max-height: 400px;z-index: 2;border:1px solid black;border-radius: 10px; display: none;min-width: 150px;overflow-y: hidden;"
      id="legend_container">
      <div
        style="width: 100%;;max-height:30px;display: flex;align-items: center;justify-content: space-between;padding: 5px;background-color: gray;font-weight: bold;">
        <span>Legend</span><span class="material-symbols-outlined " style="cursor:pointer;"
          id="legend_collapse_expand_icon">remove</span>
      </div>
      <div style="padding: 5px;display: flex;width: max-content;align-items: flex-end;">

        <div style="max-height: 380px;overflow-y: scroll;z-index: 2;padding: 5px;position: relative;"
          id="graph_node_legend" class="scrollbarcustom2-left">
        </div>
        <div
          style="height: 380px;overflow-y: scroll;z-index: 2;padding: 5px;position: relative;display:none;min-width: 200px;overflow-x: hidden;"
          id="node_color_menu" class="scrollbarcustom2-left">
        </div>
      </div>
    </div>


  </div>
  <div id="errorDiv"
    style="z-index:1;height:100%; width: 100%;padding: 0; background-color: #cfd8dc;position: relative;text-align: center;display: flex;align-items: center;justify-content: center;">
    <span>An error has occured. Try reloading the page.If the problem still persists, please contact Orglens Pvt.
      Ltd.</span>
  </div>
</div>