import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable} from 'rxjs';
import jwtDecode from 'jwt-decode';

@Injectable()
export class AppService {

    public parentID: any;
    public baseURL :string = "https://orglens.in/api/v1/";
    constructor(
        private httpClient: HttpClient
    ) { 
      if(this.getUrlParameter('prod') == "true"){
        console.log("base url")
        this.baseURL = "https://app-eu.orglensglobal.com/api/v1/";
      }
    }

    dbcall(id: any, nteworkName: any): any {
      return this.httpClient.get('http://ec2-35-164-82-153.us-west-2.compute.amazonaws.com/api/cyto_json/' + id + '/' + nteworkName);
  }
  getUrlParameter(sParam: string) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? '' : decodeURIComponent(sParameterName[1]);
      }
    }
    return '';
  };

 // getTableData(id: any): Observable<any> {
   // return this.httpClient.get('http://ec2-100-20-118-156.us-west-2.compute.amazonaws.com/core/fetch_graphlist_data/' + id);

 // }
  getTableData(id: any): Observable<any> {
    return this.httpClient.get('http://ec2-52-41-9-50.us-west-2.compute.amazonaws.com/core/fetch_graphlist_data/' + id);

  }



   getGraph(key: string, fileName: string) {
    var localkey = jwtDecode(key)
    localkey = localkey['study_id'];
    //changed by Viraj on 14-04-2023

    if($("#survey_dd").val()== "pre"){
      return  this.httpClient.get("assets/graph_jsons/"+localkey+"/pre/"+fileName+".json")
 
    }
    else if($("#survey_dd").val()== "post"){
      return  this.httpClient.get("assets/graph_jsons/"+localkey+"/post/"+fileName+".json")
 
    }
    else if($("#survey_dd").val()== "final"){
      return  this.httpClient.get("assets/graph_jsons/"+localkey+"/final/"+fileName+".json")
 
    }
    else if(this.getUrlParameter('local') == "true"){
      return  this.httpClient.get("assets/graph_jsons/"+localkey+"/"+fileName+".json")
 
     }
    const headers = new HttpHeaders()
    .set('key', key)
    .set('Content-Type', 'application/json');;
    if(this.getUrlParameter('dev') == "true"){
      this.baseURL = "https://orglens.org/api/v1/"
    }
    return this.httpClient.post(this.baseURL + "getjsonasset/"+fileName,"",{headers})    // return this.httpClient.get('http://ec2-52-41-9-50.us-west-2.compute.amazonaws.com/core/get_graph_json/' + key + '/' + fileName);
  }
  getTeamGraph(key: string, fileName: string) {
    var localkey:any = jwtDecode(key)
    localkey = localkey['study_id'];
    //  if(parseInt(localkey) > 727 ){
    //   const headers = new HttpHeaders()
    //   .set('key', key)
    //   .set('Content-Type', 'application/json');;
    //   return   this.httpClient.post("https://orglens.org/api/v1/getjsonasset/"+fileName,"",{headers})
    //  }
      return  this.httpClient.get("assets/graph_jsons/"+localkey+"/teams/"+fileName+".json")

     

    //changed by Viraj on 14-04-2023
    // return this.httpClient.post("https://orglens.in/api/v1/getjsonasset/"+fileName,{key:key})
    // return this.httpClient.get('http://ec2-52-41-9-50.us-west-2.compute.amazonaws.com/core/get_graph_json/' + key + '/' + fileName);
  }
  getIndividualGraph(key: string, fileName: string) {
    var localkey = jwtDecode(key)
    localkey = localkey['study_id'];
    // if(this.getUrlParameter('new_team_indi_files') == "true" ){
      const headers = new HttpHeaders()
      .set('key', key)
      .set('Content-Type', 'application/json');;
      if(this.getUrlParameter('dev') == "true"){
        this.baseURL = "https://orglens.org/api/v1/"
      }
      return this.httpClient.post(this.baseURL + "getjsonasset/"+fileName,"",{headers})
    // }
    // else{
    //   return  this.httpClient.get("assets/graph_jsons/"+localkey+"/ind-graph-jsons/"+fileName+".json")

    // }

    //changed by Viraj on 14-04-2023
    // return this.httpClient.post("https://orglens.in/api/v1/getjsonasset/"+fileName,{key:key})
    // return this.httpClient.get('http://ec2-52-41-9-50.us-west-2.compute.amazonaws.com/core/get_graph_json/' + key + '/' + fileName);
  }

  async getConfig_reportJson(key: string){
    var data = JSON.stringify({"filename":"config_report"})
    var localkey = jwtDecode(key)
    localkey = localkey['study_id'];
    const headers = new HttpHeaders()
    .set('key', key)
    .set('Content-Type', 'application/json');

    if(this.getUrlParameter('local')== "true"){
     return await this.httpClient.get("assets/config_jsons/"+localkey+"/config_report.json").toPromise()

    }
    else{
      if(this.getUrlParameter('dev') == "true"){
        this.baseURL = "https://orglens.org/api/v1/"
      }
      console.log("this.baseurl",this.baseURL);
      // console.log("report auth ",this.httpClient.post("https://orglens.org/jwt/authenticate-report/","" ,{headers}).toPromise())
      return await this.httpClient.post(this.baseURL+"getjson/",data ,{headers}).toPromise()
    }
    
  }
  async getGraph_specifications(key: string = ''){

    return await this.httpClient.get("assets/graph_config_specifications.json").toPromise()

    // return await this.httpClient.post(this.baseURL+"getjson/",data ,{headers}).toPromise()
  }

  pushConfig(id: any, config: any) {
    const headers = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    return this.httpClient.post('http://ec2-100-20-118-156.us-west-2.compute.amazonaws.com/core/process_config_on_demand/' + id, config, headers);
  }

  async getInsightJson(study_id:string,name:string){
    if(this.getUrlParameter('local')== "true"){

    return await this.httpClient.get('assets/report_v2_files_'+study_id+'/'+name+".json").toPromise();
    }
    else{
      var key = this.getUrlParameter('uid')
    
      const headers = new HttpHeaders()
      .set('key', key)
      .set('Content-Type', 'application/json');
      if(this.getUrlParameter('dev') == "true"){
        this.baseURL = "https://orglens.org/api/v1/"
      }
      return this.httpClient.post(this.baseURL + "getjsonasset/"+name,"",{headers}).toPromise() 
    }


  }
  async getv3InsightJson(study_id:string,name:string){
    if(this.getUrlParameter('local')== "true"){
      return await this.httpClient.get('assets/report_v3_files_'+study_id+'/'+name+".json").toPromise();

    }
    else{
      var key = this.getUrlParameter('uid')
    
      const headers = new HttpHeaders()
      .set('key', key)
      .set('Content-Type', 'application/json');;
      if(this.getUrlParameter('dev') == "true"){
        this.baseURL = "https://orglens.org/api/v1/"
      }
      return this.httpClient.post(this.baseURL + "getjsonasset/"+name,"",{headers}).toPromise()    }


  }
  async generate_layout(formValues:any){
   
    
      var key = this.getUrlParameter('uid')
    
      const headers = new HttpHeaders()
      .set('key', key)
      .set('Content-Type', 'application/json');;
      if(this.getUrlParameter('dev') == "true"){
        this.baseURL = "https://orglens.org/api/v1/"
      }
      console.log('formvalues ',formValues);
      return this.httpClient.post("http://one-tool.ap-southeast-2.elasticbeanstalk.com/api/generate_single_layout_positions/1",formValues,{headers}).toPromise()


  }

  async getGraphPositions(name : string,key:string,layout_type:string){
    var study_id: any = jwtDecode(key)
    study_id = study_id["study_id"]
    if(layout_type == 'Forceatlas2-Noverlap'){
      console.log("Fetching Forceatlas 2")
      if($("#survey_dd").val()== "pre"){
        return await this.httpClient.get('assets/core_'+study_id+'_positions_new/pre/'+name+"_positions.json").toPromise()
   
      }
      if($("#survey_dd").val()== "post"){
        return await this.httpClient.get('assets/core_'+study_id+'_positions_new/post/'+name+"_positions.json").toPromise()
   
      }
      if($("#survey_dd").val()== "final"){
        return await this.httpClient.get('assets/core_'+study_id+'_positions_new/final/'+name+"_positions.json").toPromise()
   
      }
      // if(parseInt(study_id) > 727 ){
      //   const headers = new HttpHeaders()
      //   .set('key', key)
      //   .set('Content-Type', 'application/json');;
      //   return   this.httpClient.post("https://orglens.org/api/v1/getjsonasset/"+name+"_positions","",{headers})
      //  }
      //  else{
        return await this.httpClient.get('assets/core_'+study_id+'_positions_new/'+name+"_positions.json").toPromise();

      //  }

    }
    else if (layout_type == "Fruchterman-Reingold"){
      console.log("Fetching Fruchterman Reingold")
      // if(parseInt(study_id) > 727 ){
      //   const headers = new HttpHeaders()
      //   .set('key', key)
      //   .set('Content-Type', 'application/json');;
      //   return   this.httpClient.post("https://orglens.org/api/v1/getjsonasset/"+name+"_positions_fruchterman","",{headers})
      //  }
      //  else{
        return await this.httpClient.get('assets/core_'+study_id+'_positions_new/'+name+"_positions_fruchterman.json").toPromise();

      //  }

    }
  }

  async getKCore(name : string,study_id:string){
    return await this.httpClient.get('assets/report_v2_files_'+study_id+'/core-kcore/'+name+"_k_core.json").toPromise();

  }
  


}
